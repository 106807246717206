import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Button } from '@/stories/buttons/button'
import { Section } from '@/stories/marketing/content-wrappers'
import { breakpoint } from '@/theme'
import onClickEvents from '@helpers/home/on-click-callbacks'
import { changeQuery, getHref } from '@helpers/path'
import ROUTES from '@helpers/routes'
import { CombinedShapeIcon, LightningIcon, SmileIcon } from '@microcomponents/icons/new'

import { TEST_IDS } from './test/constants'

import { bool, func, InferProps, shape, string } from 'prop-types'

export default function ThreeColumnLayout(props: InferProps<typeof ThreeColumnLayout.propTypes>) {
  const router = useRouter()

  function handleClick() {
    const bannerName = props.banner_name
    const redirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
    const redirectWithQueryParams = getHref({
      pathname: redirectLocation,
      query: changeQuery(router.query, { slug: null })
    })
    onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress, 'shop_now')
  }

  return (
    <Section componentStyle={SectionOverride}>
      <Row data-e2eid={TEST_IDS.THREE_COLUMN_LAYOUT} onHomePage={props.onHomePage}>
        <Column>
          <ColumnContent onClick={handleClick}>
            <LightningIcon />
            <Title>On-demand delivery</Title>
            <Description>Shop on Eaze and get your weed delivered on demand, wherever you are.</Description>
          </ColumnContent>
          <ColumnBackground>
            <Image src={`/static/images/column-layout-bg-0@2x.png`} width={286} height={288} />
          </ColumnBackground>
        </Column>
        <Column>
          <ColumnContent onClick={handleClick}>
            <CombinedShapeIcon />
            <Title>Dank selection</Title>
            <Description>We calculated the best selection for any experience or price range.</Description>
          </ColumnContent>
          <ColumnBackground>
            <Image src={`/static/images/column-layout-bg-1@2x.png`} width={286} height={288} />
          </ColumnBackground>
        </Column>
        <Column>
          <ColumnContent onClick={handleClick}>
            <SmileIcon />
            <Title>Cannabis for a cause</Title>
            <Description>We always stock women and BIPOC owned brands, so you can spend on what matters.</Description>
          </ColumnContent>
          <ColumnBackground>
            <Image src={`/static/images/column-layout-bg-2@2x.png`} width={286} height={288} />
          </ColumnBackground>
        </Column>
      </Row>
      {!props.onHomePage && props.call_to_action && (
        <Button variant={'variant-1'} onClick={handleClick}>
          {props.call_to_action.title}
        </Button>
      )}
    </Section>
  )
}

ThreeColumnLayout.propTypes = {
  onHomePage: bool,
  toggleAddress: func.isRequired,
  call_to_action: shape({
    title: string,
    url: string
  }),
  banner_name: string.isRequired // For Segment tracking: This field will be used to track the name of the 3 column layout that was used when the Banner.Click event is triggered
}
ThreeColumnLayout.defaultProps = {
  onHomePage: true
}

const SectionOverride = css`
  text-align: center;
`

const Row = styled.div<{ onHomePage: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: ${breakpoint.min.lg}px;
  margin: 0 auto 60px;
  gap: 20px;

  ${({ onHomePage }) =>
    onHomePage &&
    `
    margin-top: -145px;
  `}

  @media (max-width: ${breakpoint.max.lg}px) {
    justify-content: center;
    gap: 40px;
  }
`

const Column = styled.div`
  margin-top: 40px;
  position: relative;

  svg {
    height: 50px;
    width: 50px;
  }
`

const ColumnContent = styled.button`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  cursor: pointer;
  height: 286px;
  padding: 40px 32px 24px;
  position: relative;
  width: 292px;
  z-index: 2;
`

const ColumnBackground = styled.div`
  position: absolute;
  bottom: -12px;
  right: -6px;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.typography.font.npBold};
  font-size: ${({ theme }) => theme.typography.size.desktop.title2}px;
  margin: 0 0 8px;
  line-height: normal;

  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Description = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.typography.font.sim};
  font-size: ${({ theme }) => theme.typography.size.desktop.body3}px;
  margin: 0;
`
